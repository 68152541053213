export default {
  namespaced: true,

  state: {
    propertiesList: [],
    propertyItem: null,
  },
  getters: {
    propertiesList(state) {
      return state.propertiesList
    },
    propertyItem(state) {
      return state.propertyItem
    },
  },
  mutations: {
    SET_PROPERTIES(state, propertiesList) {
      state.propertiesList = propertiesList
    },
    SET_PROPERTY(state, propertyItem) {
      state.propertyItem = propertyItem
    },
  },
}
