import i18n from '@/libs/i18n'

export default [
  {
    path: '/properties/all',
    name: 'all_properties',
    component: () => import('@/views/pages/properties/AllProperties'),
    meta: {
      pageTitle: i18n.t('menu.properties.children.all_properties'),
      resource: 'all_properties',
      action: 'read',
    },
  },
  {
    path: '/properties/new',
    name: 'new_property',
    component: () => import('@/views/pages/properties/AddNewProperty'),
    meta: {
      pageTitle: i18n.t('menu.properties.children.new_property'),
      resource: 'create_property',
      action: 'read',
    },
  },
  {
    path: '/properties/edit/:id',
    name: 'edit_property',
    component: () => import('@/views/pages/properties/EditProperty'),
    meta: {
      pageTitle: i18n.t('menu.properties.children.edit_property'),
      resource: 'edit_property',
      action: 'read',
    },
  },
]
