import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import i18n from '@/libs/i18n'
import dashboard from './routes/dashboard'
import auth from './routes/auth'
import properties from './routes/properties'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'dashboard' } },
  ...dashboard,
  ...auth,
  ...properties,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, _, next) => {
  document.title = to.meta.pageTitle ? `${to.meta.pageTitle} - ${i18n.t('app_name')}` : i18n.t('app_name')
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'error-404' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
